import { useEffect, useRef } from "react";
import Gradient from "./helper/gradient";

const CONTACT_EMAIL = "hcd36@cornell.edu";

function App() {
  const gradient = useRef(null);

  useEffect(() => {
    // @ts-ignore
    document.body.style = "background: #091121";
    const G = new Gradient();
    // @ts-ignore
    gradient.current = G;
    // @ts-ignore
    gradient.current.initGradient("#gradient-canvas");
    // @ts-ignore
    return () => gradient.current?.disconnect && gradient.current?.disconnect();
  });

  return (
    <div>
      <canvas id="gradient-canvas" />
      <main className="bg-grid-slate-100 flex flex-1 flex-col justify-center items-center min-h-screen">
        <div className="pb-8 max-w-3xl self-center">
          <h2 className="m-0 text-5xl text-center">Magic Ink Press</h2>
          <p className="text-center my-8 mx-8 text-l">
            {`Magic Ink Press leverages the power to generative AI to enable
            K-4th graders to create and edit their own childrens books complete
            with compelling narratives, engaging characters, and stunning
            illustrations. We aim to promote a love of reading, foster
            creativity, and increase representation. After a story is completed,
            parents are given the opportunity to buy a physical copy of their
            child’s creation, providing a lasting keepsake to remember a special
            moment in their kid’s life`}
          </p>
          <div className="flex justify-center items-centers">
            <button className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-yellow-500 to-orange-500 group-hover:from-purple-500 group-hover:to-gold-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
              <a
                className="relative px-5 py-2.5 transition-all ease-in text-xl duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
                href="https://web-build-beryl-omega.vercel.app/"
              >
                Get Started
              </a>
            </button>
            <button className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-yellow-500 to-orange-500 group-hover:from-purple-500 group-hover:to-gold-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
              <a
                className="relative px-5 py-2.5 transition-all ease-in text-xl duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
                href="https://docs.google.com/forms/d/e/1FAIpQLSeHV3ieD48Pdt9HYWfmm783Zijuoj1uTwN8RaJngVWB64nX9w/viewform"
              >
                Join Waitlist
              </a>
            </button>
          </div>
        </div>
      </main>
      <div style={{ backgroundColor: "#1B0A36", color: "#fefefe" }}></div>
      <footer className="w-full h-[100px] flex justify-center items-center bg-footer">
        Want to speak to us?{" "}
        <a className="ml-2 indigo" href={`mailto:${CONTACT_EMAIL}`}>
          {CONTACT_EMAIL}
        </a>
      </footer>
    </div>
  );
}

export default App;
